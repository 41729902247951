import React, { useState, useEffect } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import FeaturedListingProperty from '../components/index/FeaturedListingProperties/FeaturedListingProperty'
import SearchPropertyFilter from "../components/index/SearchPropertyFilter/SearchPropertyFilter"
import { useIntl,injectIntl } from "gatsby-plugin-intl";
import propertiesData from "../../build_data/properties.json";
import InfiniteScroll from "react-infinite-scroll-component";
import { css } from "@emotion/core";
import DotLoader from "react-spinners/DotLoader";


const queryString = require('query-string');

function PropertySales({ data }){
  const intl = useIntl();

  const [properties] = useState(propertiesData.filter(p => p.published).sort(function(a,b){
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.created_at) - new Date(a.created_at);
  }));
  const [propertiesFiltered, setPropertiesFiltered] = useState(JSON.parse(JSON.stringify(properties)));
  const [visibleProperties, setVisibleProperties] = useState(JSON.parse(JSON.stringify(propertiesFiltered.slice(0, 200))));
  const [showNProperties, setShowNProperties] = useState(18);
  const [loading, setLoading] = useState(true);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  useEffect(() => {
    setVisibleProperties([ ...propertiesFiltered.slice(0, showNProperties)])
  }, [propertiesFiltered, showNProperties]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  });

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('showedProperties')
    }
  })

  function callbackFunction(searchContent) {
    var data = JSON.parse(JSON.stringify(properties));

    //search filters
    if(searchContent.id !== undefined){
      data = data.filter(p => p.id === parseInt(searchContent.id));
    }
    if(searchContent.propertyType !== undefined){
      var types = searchContent.propertyType.split(",");
      data = data.filter(p => types.indexOf(p.type) !== -1);
    }
    if(searchContent.municipality !== undefined){
      var municipalities = searchContent.municipality.split(",");
      data = data.filter(p => municipalities.indexOf(p.municipality) !== -1);
    }
    //if the filter only includes zones
    if(searchContent.municipality === undefined && searchContent.zone !== undefined){
      var zones = searchContent.zone.split(",");
      data = data.filter(p => zones.indexOf(p.Zone) !== -1);
    }
    //if there are municipalites, we have to set an smart behavior
    else if(searchContent.municipality !== undefined && searchContent.zone !== undefined){
      var zonesLocal = searchContent.zone.split(",");
      data = data.filter(p => {
        var isValid = false;
        var propertyMunicipality = p.municipality;
        var isAZoneForMunicipality = zonesLocal.filter(s => s.includes(propertyMunicipality)).length > 0;
        if(!isAZoneForMunicipality){
            isValid = true;
        }
        else{
          isValid = zonesLocal.indexOf(p.Zone) !== -1;
        }
        return isValid;
      });
    }
    if(searchContent.minPrice !== undefined && searchContent.minPrice > 0){
      data = data.filter(p => p.price >= searchContent.minPrice);
    }   
    else if(searchContent.minPrice !== undefined && searchContent.minPrice < 0){
      data = data.filter(p => p.price > (searchContent.minPrice * -1));
    } 
    if(searchContent.maxPrice !== undefined && searchContent.maxPrice > 0){
      data = data.filter(p => p.price <= searchContent.maxPrice);
    }   
    else if(searchContent.maxPrice !== undefined && searchContent.maxPrice< 0){
      data = data.filter(p => p.price > (searchContent.maxPrice * -1));
    }   
    if(searchContent.rooms !== undefined){
      var rooms = searchContent.rooms.split(",");
      data = data.filter(p => rooms.indexOf(p.bedrooms.toString()) !== -1);
    } 
    if(searchContent.complex !== undefined){
      var complexFilter = searchContent.complex.split(",");
      data = data.filter(p => complexFilter.indexOf(p.complex) !== -1);
    }
    if(searchContent.extras !== undefined){
      var extras = searchContent.extras.split(",");
      if(extras.indexOf("pool") !== -1){
        data = data.filter(p => p.pool !== "Does_not_have");
      }
      if(extras.indexOf("garage") !== -1){
        data = data.filter(p => p.garage_meters !== null);
      }
      if(extras.indexOf("is_featured") !== -1){
        data = data.filter(p => p.is_featured);
      }
      if(extras.indexOf("new_development") !== -1){
        data = data.filter(p => p.is_new_construction);
      }
      if(extras.indexOf("is_luxury") !== -1){
        data = data.filter(p => p.is_luxury);
      }
      if(extras.indexOf("is_investment") !== -1){
        data = data.filter(p => p.is_investment);
      }
      if(extras.indexOf("is_re_sale") !== -1){
        data = data.filter(p => p.is_re_sale);
      }
      if(extras.indexOf("Golf") !== -1){
        data = data.filter(p => p.place === "Golf");
      }
      if(extras.indexOf("Coast") !== -1){
        data = data.filter(p => p.place === "Coast");
      }
      if(extras.indexOf("Town") !== -1){
        data = data.filter(p => p.place === "Town");
      }
      if(extras.indexOf("Mountain") !== -1){
        data = data.filter(p => p.place === "Mountain");
      }
    } 
    //After one search, we must order by price. Requirement 02/2020
    //show property should not trigger a search because its only numeration
    if(!(Object.keys(searchContent).length == 1 && searchContent.show !== undefined) && JSON.stringify(searchContent) !== JSON.stringify({})){
      data = data.sort((a,b) => (a.price-b.price));
    }
    if(searchContent.show !== undefined){
      setShowNProperties(parseInt(searchContent.show));
    }
    
    // If showedProperties has been setted in sessionStorage:
    let showedP = sessionStorage.getItem('showedProperties');
    console.log("PropertySales -> showedP", showedP)
    showedP != null 
      && setShowNProperties(parseInt(showedP));

    propertiesFiltered.splice(0,propertiesFiltered.length);
    propertiesFiltered.push.apply(propertiesFiltered,data);
    setPropertiesFiltered([ ...propertiesFiltered]);
  }

  function increaseVisibleProperties() {
    let newProperties = showNProperties + 12; 
    setShowedIndexInHash(newProperties);
    setShowNProperties(newProperties);
    setVisibleProperties([ ...propertiesFiltered.slice(0, newProperties)])
  }

  function setShowedIndexInHash(value){
    setShowNProperties(value);
    const parsed = queryString.parse(window.location.hash);
    parsed.show = value;
    const stringified = queryString.stringify(parsed);
    if(window.history.pushState) {
      window.history.pushState(null, null, "#" + stringified);
    }
    else {
        window.location.hash = stringified;
    }
  }

  function openProperty() {
    sessionStorage.setItem('showedProperties', showNProperties);
  }

    return(
    <Layout>
      <SEO title={intl.formatMessage({ id:'Menu-propertySales'})} />

          {loading && 
            <div className="dots-loader">
                <DotLoader
                  css={override}
                  size={150}
                  color={"#09753b"}
                  loading={loading}
                />
            </div>
          }

          <div 
            id="property-sales-container"
            style={{
              paddingTop: 80
            }}
          >
                     
            {/* FILTER */}
            <SearchPropertyFilter
              parentCallback = {callbackFunction} 
            >
            </SearchPropertyFilter>

            <div className="site-breadcrumb">
              <div className="container">
              </div>
            </div>

            <section className="page-section categories-page">
              <div className="container">
                  <InfiniteScroll
                    className="properties-infinite-scroll"
                    dataLength={visibleProperties.length}
                    next={increaseVisibleProperties}
                    hasMore={propertiesFiltered.length > visibleProperties.length}
                    scrollThreshold={0.9}
                    loader={<h4>...</h4>}
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        <b>You have seen it all!</b>
                      </p>
                    }
                  >
                    <div className="row">
                    {
                      visibleProperties.filter(p => p.published).map(p => { 
                        if(p.images.length > 0){                      
                          return <FeaturedListingProperty 
                              property={p}
                              onClickCallback={openProperty} 
                              key={p.id} 
                              intl={intl}
                            >
                            </FeaturedListingProperty>
                        }
                      })
                    }
                  </div>
                </InfiniteScroll>
              </div>
            </section>

          </div>
   
    </Layout>
    );
  }

export default injectIntl(PropertySales)

export const query = graphql`
query {
  pageTopBg: file(relativePath: { eq: "page-top-bg.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 300) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`